import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Header from '../components/invita-a-un-amigo/Header';
import Footer from '../components/Footer';

import ReferAFriendForm from 'contactfo/src/ReferAFriendForm';

import FreshChat from 'aulatime/src/components/FreshChat';

const InvitaAUnAmigoPage = ({ data }) => {

  const postmanpConfig = {
    ...data.site.siteMetadata.station.metadata,
    ...{ lang: process.env.GATSBY_LOCALE || 'es' },
    ...{ form: 'refer-a-friend' }
  };

  return (
    <Layout>
      <SEO title="Invita a un amigo"/>
      <main id="invita-a-un-amigo" className="marketing">
        <Header/>
        <section id="saludar">
          <div className="container">
            <div className="row">

              <div className="col-lg-10 offset-lg-1 col-12 text-center">
                <h2>Recibe un <span className="text-primary-2">10%</span> descuento en tu próxima compra</h2>
                <p>Cuando un amigo tuyo se registra en uno de nuestros cursos.</p>
              </div>

            </div>
          </div>
        </section>

        <section id="contacto">
          <div id="contactoForm">
            <div className="container pb-5">

              <div className="rounded col-lg-8 offset-lg-2 col-12 px-0">
                <ReferAFriendForm
                  metadata={postmanpConfig}
                />
              </div>

            </div>
          </div>
        </section>
        <Footer/>
      </main>
      <FreshChat config={data.site.siteMetadata.freshchat}/>
    </Layout>
  );
}

export default InvitaAUnAmigoPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        freshchat {
          token
          host
          locale
          siteId
          tags
          config {
            headerProperty {
              appName
              appLogo
              backgroundColor
            }
          }
        }
        station {
          metadata {
            domain
            shortcode
          }
        }
      }
    }
  }
`
